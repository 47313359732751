body {
  background-color: #0d5010;
  color: #b9f062;
}

.mainContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 64px;
}

.dataContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 600px;
}

.header {
  text-align: center;
  font-size: 32px;
  font-weight: 600;
}

.bio {
  text-align: center;
  color: #cec489;
  margin-top: 16px;
}

.cta {
  text-align: center;
  color: #cec489;
  margin-top: 16px;
  max-width: 450px;
}

.mintButton {
  cursor: pointer;
  margin-top: 16px;
  padding: 12px;
  border: 0;
  border-radius: 5px;
}

.openSeaLnk {
  cursor: pointer;
  margin-top: 16px;
  padding: 12px;
  border: 0;
  border-radius: 5px;
  background-color: #b9f062;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #08310a;
  color: #21da2a;
  text-align: center;
}

.footButton {
  cursor: pointer;
  margin-top: 4px;
  padding: 4px;
  border: 0;
  border-radius: 3px;
  background-color: #b9f062;
}